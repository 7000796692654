<!-- TEMPLATE -->
<template>
    <div class="header shadow-drop md:rounded-2xl mobile:rounded-br-3xl mobile:rounded-bl-3xl">
        <div v-show="!isSubmitted" class="header-pre flex flex-col min-h-[55vh] items-center justify-center p-8 gap-6">
            <div class="header-top flex flex-row items-center justify-center gap-4">
                <img src="@/assets/logo.svg" alt="Logo" class="h-10 w-10" />
                <h3 class="font-semibold text-white text-2xl">Efektive</h3>
            </div>

            <div class="header-title flex sm:flex-row flex-col items-center gap-3 sm:gap-4">
                <h1 class="font-bold text-white text-6xl mobile:text-5xl text-center">Find den perfekte </h1>

                <div class="header-title-moving rounded-lg font-bold text-theme text-6xl mobile:text-5xl px-3 py-2 backdrop-blur-2xl">
                    <VueTypewriterEffect
class="typewrite" wrapper-class-name="typewrite-wrapper" cursor-class-name="typewrite-cursor" element="h1" :strings="[
                        'freelancer',
                        'videograf',
                        'programmør',
                        'tekstforfatter',
                        'fotograf',
                        'designer',
                        'SEO specialist']" 
                    />
                </div>
            </div>

            <div class="header-text max-w-3xl text-center max-w-[40rem]">
                <span class="text-theme-grey font-medium">Tilmeld dig vores venteliste for at være den første til at høre om lanceringen af vores nye platform, der forenkler interaktionen mellem freelancere og kunder i 🇩🇰 Danmark. Gå ikke glip af vigtige opdateringer og eksklusive tilbud!</span>
            </div>

            <div class="header-action mobile:w-full flex flex-col gap-4 items-center">
                <div class="flex sm:flex-row flex-col gap-4 mobile:w-full">
                    <!-- Full Name Input -->
                    <input
                        v-model="first_name"
                        type="text"
                        placeholder="Fornavn"
                        class="flex-1 w-full py-3 px-4 bg-transparent border border-gray-500 rounded-2xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#6f63e8]"
                    />

                    <input
                        v-model="last_name"
                        type="text"
                        placeholder="Efternavn"
                        class="flex-1 w-full py-3 px-4 bg-transparent border border-gray-500 rounded-2xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#6f63e8]"
                    />

                    <!-- Email Input -->
                    <input
                        v-model="email"
                        type="email"
                        placeholder="E-mail"
                        class="flex-1 w-full py-3 px-4 bg-transparent border border-gray-500 rounded-2xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#6f63e8]"
                    />
                </div>

                <div class="flex sm:flex-row flex-col gap-4 mobile:w-full">
                    <!-- Type Input -->
                    <div class="flex-1 min-w-[14rem] mobile:!w-full">
                        <Listbox v-model="selectedOption">
                            <div class="relative h-full">
                                <ListboxButton
                                    class="relative flex items-center w-full h-full flex py-3 pl-4 text-left bg-transparent border border-gray-500 rounded-2xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#6f63e8]"
                                >
                                    <span class="block truncate">{{ selectedOption.name }}</span>
                                    <span
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
                                    >
                                        <IconArrowDown
                                            class="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </ListboxButton>

                                <transition
                                    leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100"
                                    leave-to-class="opacity-0"
                                >
                                    <ListboxOptions
                                        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white shadow-lg ring-1 ring-black/5 focus:outline-none focus:ring-2 focus:ring-[#6f63e8] sm:text-sm"
                                    >
                                        <ListboxOption
                                            v-for="option in options"
                                            v-slot="{ active, selected }"
                                            :key="option.name"
                                            :value="option"
                                            as="template"
                                        >
                                        <li
                                            :class="[
                                                active ? 'bg-[#6f63e8] text-white' : 'text-black',
                                                'relative cursor-default select-none py-2 pl-3 pr-4 border-b border-gray-500 text-left',
                                            ]"
                                        >
                                            <span
                                                :class="[
                                                    selected ? 'font-bold' : 'font-normal',
                                                    'block truncate',
                                                ]"
                                            >{{ option.name }}</span>
                                        </li>
                                        </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                            </div>
                        </Listbox>
                    </div>

                    <!-- Submit Button -->
                    <button
                        class="group flex gap-3 min-w-[12rem] items-center justify-between px-4 py-3 bg-white text-gray-900 font-semibold rounded-2xl shadow-md hover:bg-[#F1EDF9] transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#6f63e8]"
                        @click="submitWaitlist"
                    >
                        <span>Tilslut Venteliste</span>
                        <span class="flex-1 h-full max-w-[2rem] flex items-center justify-center bg-gray-200 group-hover:bg-[#BCA7FA] aspect-square transition duration-200 ease-in-out rounded-lg aspect-square text-gray-700">
                            <img src="@/assets/arrow.svg" class="h-3 w-3" />
                        </span>
                    </button>
                </div>


            <div v-for="error of v$.$errors" :key="error.$uid" class="input-errors py-2">
                    <div class="error-msg text-white">{{ error.$message }}</div>
                </div>
            </div>
        </div>

        <div v-show="isSubmitted" class="header-post flex flex-col min-h-[55vh] max-h-[65vh] mobile:max-h-[90vh] items-center justify-center p-8 gap-6">
            <div class="header-top flex flex-row items-center justify-center gap-4">
                <img src="@/assets/logo.svg" alt="Logo" class="h-10 w-10" />
                <h3 class="font-semibold text-white text-2xl">Efektive</h3>
            </div>

            <div class="header-title flex sm:flex-row flex-col items-center gap-3 sm:gap-4">
                <h1 class="font-bold text-white text-6xl mobile:text-5xl text-center">Tak fordi du tilmeldte dig <br> vores venteliste! </h1>
            </div>

            <div class="header-text max-w-3xl text-center max-w-[40rem]">
                <span class="text-theme-grey font-medium">Du er nu på ventelisten til Efektive. Vi er glade for at have dig med! Vi holder dig opdateret med de seneste nyheder og giver besked, så snart platformen lanceres.
                    <br>
                    I mellemtiden er du velkommen til at dele denne spændende nyhed med dine venner og kollegaer!
                </span>
            </div>

            <div class="header-icons">
                <div class="flex gap-4">
                    <a href="https://www.facebook.com/share/1CbZ5bakK2" target="_blank" class="flex items-center justify-center h-12 w-12 rounded-xl bg-white text-black">
                        <img src="@/assets/facebook.svg" class="h-6 w-6" />
                    </a>

                    <a href="https://www.instagram.com/efektive.dk" target="_blank" class="flex items-center justify-center h-12 w-12 rounded-xl bg-white text-black">
                        <img src="@/assets/instagram.svg" class="h-6 w-6" />
                    </a>

                    <a href="https://x.com/EfektiveDK" target="_blank" class="flex items-center justify-center h-12 w-12 rounded-xl bg-white text-black">
                        <IconBrandTwitter class="h-6 w-6 text-black" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Imports
import VueTypewriterEffect from "vue-typewriter-effect";
import { submitWaitlist, updateWaitlist } from "@/api/waitlist";

import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';

import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

// Icons
import { IconArrowDown, IconBrandTwitter, } from '@tabler/icons-vue';

// Vue export
export default {
    name: "Header",
    components: { 
        VueTypewriterEffect, 
        Listbox,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        IconArrowDown,
        IconBrandTwitter,
    },
    props: {},
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            first_name: "",
            last_name: "",
            email: "",
            isSubmitted: false,
            isUpdated: false,
            selectedOption: { id: 'freelance', name: 'Jeg er freelancer' },

            options: [
                { id: 'freelance', name: 'Jeg er freelancer' },
                { id: 'company', name: 'Jeg søger freelancers' },
            ],
        };
    },
    validations () {
        return {
            first_name: { 
                required: helpers.withMessage('Fornavn er påkrævet', required),
            },
            last_name: { 
                required: helpers.withMessage('Efternavn er påkrævet', required),
            },
            email: { 
                required: helpers.withMessage('E-mail er påkrævet', required),
                email: helpers.withMessage('E-mail er ugyldig', email), 
            },
        }
    },
    computed: {
    },
    watch: {},
    mounted() {},
    beforeUnmount() {},
    methods: {
        async submitWaitlist() {
            try {
                const validated = await this.v$.$validate();
                if(!validated) return;

                const { data } = await submitWaitlist({
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    type: this.selectedOption.id,
                });

                if(data?.message === 'User added to waitlist') {
                    this.isSubmitted = true;
                }
            } catch(error) {
                console.error(error);
            }
        }
    }
};
</script>

<style lang="scss" scoped >
.header {
    background: linear-gradient(180deg, #111011 0%, #1B1622 49.5%, #372C59 100%);
}

.typewrite {
    background: #17151B;

    ::v-deep &-wrapper {
        display: inline-flex;
    }

    ::v-deep &-cursor {
        display: inline-flex;
        overflow: hidden;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 70%;
        height: 1px;
        background: linear-gradient(to right, rgba(78, 62, 154, 0) 0%, #4E3E9A 50%, rgba(78, 62, 154, 0) 100%);
    }

    &::before {
        top: 0;
        left: 15%;
    }

    &::after {
        bottom: 0;
        left: 15%;
    }
}
</style>