<!-- TEMPLATE -->
<template>
    <div class="waitlist flex flex-col gap-20 mobile:gap-16">
        <!-- HEADER -->
        <Header />

        <!-- FEATURES -->
        <Features />

        <!-- FOOTER -->
        <Footer />
    </div>
</template>

<script>
// Imports
import Header from "@/components/Header.vue";
import Features from "@/components/Features.vue";
import Footer from "@/components/Footer.vue";

// Vue export
export default {
    name: "Waitlist",
    components: { Header, Features, Footer },
    props: {},
    computed: {},
    watch: {},
    mounted() {},
    beforeUnmount() {},
    methods: {}
};
</script>

<style scoped>
</style>