import { createRouter, createWebHistory } from "vue-router";
import Waitlist from "../views/Waitlist.vue";

const routes = [
    {
        path: "/",
        name: "Efektive Venteliste",
        component: Waitlist
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Document title
router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
});

export default router;