import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import AxiosPlugin from "@/plugins/axios";
import Clarity from '@microsoft/clarity';

// Styling
import './styles/index.css'

const app = createApp(App);

Clarity.init('pbyrkqnc5n');

app.use(AxiosPlugin);
app.use(store)
app.use(router)
app.mount("#app");