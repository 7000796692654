<!-- TEMPLATE -->
<template>
    <div class="features flex flex-col gap-16 mobile:gap-14 items-center">
        <h1 class="text-[#0F0E0F] font-semibold text-4xl text-center font-bold text-black">Vores Features</h1>

        <div class="flex flex-row mobile:flex-col gap-12 mobile:px-4 max-w-[75rem]">
            <Carousel ref="slider" class="flex-1" v-bind="config" @slide-end="handleSlide">
                <Slide v-for="(slide, index) in featureSlides" :key="index" @click="slideTo(index)">
                    <div class="feature-item w-full flex flex-row gap-3 p-3 opacity-40 rounded-xl bg-[#F1EDF9]">
                        <div class="flex items-center justify-center p-2 h-full aspect-square rounded-lg bg-white text-white">
                            <component :is="slide.icon" color="black" stroke="1" class="h-5 w-5" />
                        </div>

                        <div class="flex flex-col gap-1 text-left">
                            <h3 class="text-lg leading-6 font-semibold text-[#0F0E0F]">{{ slide.title }}</h3>
                            <span class="text-md font-medium text-[#615A67]">{{ slide.description }}</span>
                        </div>
                    </div>
                </Slide>
            </Carousel>

            <div class="h-full flex items-center justify-center">
                <div 
                    v-for="(slide, index) in featureSlides" 
                    :key="index" 
                    :class="['feature-image', { 'active': activeIndex === index, 'inactive': activeIndex !== index }]"
                >
                    <img
                        :src="slide.image"
                        class="w-full h-full object-cover"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Imports
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from "vue3-carousel/dist/carousel.min.js";

// Icons
import { IconSearch, IconShieldCheck, IconStar, IconBriefcase, IconMessageCircle, IconLock } from '@tabler/icons-vue';

const featureSlides = [
  {
    title: "Avanceret søgning",
    description: "Find den perfekte freelancer hurtigt og nemt med avancerede søgefiltre.",
    icon: IconSearch,
    image: require("@/assets/features/1.png"),
  },
  {
    title: "Sikre betalinger",
    description: "Få ro i sindet med vores pålidelige og sikre betalingssystem, der beskytter dine transaktioner effektivt.",
    icon: IconShieldCheck,
    image: require("@/assets/features/1.png"),
  },
  {
    title: "Bedømmelser & anmeldelser",
    description: "Læs og skriv anmeldelser af freelancere for at træffe informerede beslutninger.",
    icon: IconStar,
    image: require("@/assets/features/1.png"),
  },
  {
    title: "Porteføljevisning",
    description: "Vis dit arbejde frem eller gennemse freelanceres porteføljer uden besvær.",
    icon: IconBriefcase,
    image: require("@/assets/features/1.png"),
  },
  {
    title: "Real-time beskeder",
    description: "Samarbejd effektivt gennem vores brugervenlige beskedsystem, der muliggør øjeblikkelig kommunikation.",
    icon: IconMessageCircle,
    image: require("@/assets/features/1.png"),
  },
  {
    title: "Escrow-beskyttelse",
    description: "Sørg for, at betalingen er sikker, indtil projektet er afsluttet, med vores pålidelige escrow-tjeneste.",
    icon: IconLock,
    image: require("@/assets/features/1.png"),
  },
];


// Vue export
export default {
    name: "Features",
    components: {
        Carousel,
        Slide
    },
    props: {},
    data() {
        return {
            config: {
                dir: 'ttb',
                wrapAround: true,
                touchDrag: false,
                itemsToShow: 4,
                snapAlign: 'center-even',
                autoplay: 3500,
                height: '500px',
                gap: 3,
            },
            featureSlides,
            reactiveCurrentSlide: 0,
        };
    },
    computed: {
        activeIndex() {
            return this.reactiveCurrentSlide;
        },
    },
    watch: {},
    beforeUnmount() {},
    methods: {
        handleSlide(data) {
            this.reactiveCurrentSlide = data?.currentSlideIndex;
            console.log(this.reactiveCurrentSlide);
        },
        slideTo(index) {
            // Current index
            const currentSlide = this.$refs.slider.data.currentSlide;
            // Since it's an endless scroller, we need to make the transitions smooth
            // Going back to first slide, we have to evaluate which way the user is "scrolling", to not make it jump to the opposite side
            if(index === 0) {
                if(currentSlide === 1) {
                    return this.$refs.slider.prev();
                }

                return this.$refs.slider.next();
            }

            // Same applies to the last slide
            if(index === this.featureSlides.length - 1) {
                if(currentSlide === this.featureSlides.length - 2) {
                    return this.$refs.slider.next();
                }

                return this.$refs.slider.prev();
            }

            // Other slides just animate normally
            return this.$refs.slider.slideTo(index);
        },
        isActiveImage(index) {
            console.log(index);
            return this.$refs?.slider?.data?.currentSlide === index;
        }
    }
};
</script>

<style scoped>
.features {
    
}

.carousel__slide--active .feature-item {
    opacity: 1;
}

.feature-image {
  display: none;
  opacity: 0;
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.feature-image.active {
  display: block;
  opacity: 1;
}

.feature-image.inactive {
  opacity: 0;
}
</style>