<!-- TEMPLATE -->
<template>
    <footer class="bg-black md:rounded-xl mt-4 mobile:rounded-tr-3xl mobile:rounded-tl-3xl">
        <div class="max-w-7xl mx-auto px-4 py-6 flex ">
            <div class="w-1/2 flex shrink-0 items-center">
                <img src="@/assets/logo.svg" alt="Logo" class="h-10 w-10" />
                <span class="font-semibold text-lg m-2 text-white">Efektive</span>
            </div>
            <div class="w-1/2 flex gap-4 justify-end">
                <a href="https://www.facebook.com/share/1CbZ5bakK2" target="_blank" class="flex items-center justify-center h-12 w-12 mobile:h-10 mobile:w-10 rounded-xl bg-white text-black">
                    <img src="@/assets/facebook.svg" class="h-6 w-6 mobile:h-5 mobile:w-5" />
                </a>

                <a href="https://www.instagram.com/efektive.dk" target="_blank" class="flex items-center justify-center h-12 w-12 mobile:h-10 mobile:w-10 rounded-xl bg-white text-black">
                    <img src="@/assets/instagram.svg" class="h-6 w-6 mobile:h-5 mobile:w-5" />
                </a>

                <a href="https://x.com/EfektiveDK" target="_blank" class="flex items-center justify-center h-12 w-12 mobile:h-10 mobile:w-10 rounded-xl bg-white text-black">
                    <IconBrandTwitter class="h-6 w-6 mobile:h-5 mobile:w-5" />
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
// Imports
import { IconArrowDown, IconBrandTwitter, } from '@tabler/icons-vue';

// Vue export
export default {
    name: "Footer",
    components: { IconBrandTwitter },
    props: {},
    computed: {},
    watch: {},
    mounted() {},
    beforeUnmount() {},
    methods: {}
};
</script>

<style scoped>
</style>