// Module
import Axios from "axios";

// Base url
const baseURL = process.env.VUE_APP_AXIOS_URL;

const axios = Axios.create({
    baseURL: `${baseURL}/api/`,
    timeout: 30000,
    withCredentials: true
});

// Export Axios module
export { Axios };

// Export axios instance
export default axios;